import _objectSpread from "C:/Users/10666/Desktop/\u5B98\u7F51/www/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import top from "./components/top";
import fireproofA from "./components/fireproof-a";
import fireproofB from "./components/fireproof-b";
import { mapMutations } from "vuex";
export default {
  metaInfo: {
    title: "消防器材管理方案",
    // set a title
    meta: [{
      // set meta
      name: "keyWords",
      content: "RFID标签,消防器材管理方案,RFID仓储管理方案,消防设备管理的RFID解决方案,RFID手持设备,常达"
    }, {
      name: "description",
      content: "运用 RFID 技术的消防器材固定资产管理系统，通过在消防器材上安装 RFID 电子标签，在后台绑定对应的资产信息，在关键节点和流程，通过对 RFID 电子标签信息的采集，来精确获取到资产的详细信息，从而实现管理器材的快速盘点以及出入库管理， 提高效率的同时避免盘点漏缺情况的发生。"
    }]
  },
  components: {
    top: top,
    fireproofA: fireproofA,
    fireproofB: fireproofB
  },
  data: function data() {
    return {
      list: [{
        image: require("@/assets/fireproof/swiper2/1.jpg"),
        text: "数据录入",
        imgOffset: "-25%",
        icon: require("@/assets/fireproof/swiper2/icon1.png"),
        icon1: require("@/assets/fireproof/swiper2/icon1c.png")
      }, {
        image: require("@/assets/fireproof/swiper2/2.jpg"),
        text: "标签写码",
        imgOffset: "-45%",
        icon: require("@/assets/fireproof/swiper2/icon2.png"),
        icon1: require("@/assets/fireproof/swiper2/icon2c.png")
      }, {
        image: require("@/assets/fireproof/swiper2/3.jpg"),
        text: "标签安装",
        imgOffset: "-13%",
        icon: require("@/assets/fireproof/swiper2/icon3.png"),
        icon1: require("@/assets/fireproof/swiper2/icon3c.png")
      }, {
        image: require("@/assets/fireproof/swiper2/4.jpg"),
        text: "登记入库",
        imgOffset: "-40%",
        icon: require("@/assets/fireproof/swiper2/icon4.png"),
        icon1: require("@/assets/fireproof/swiper2/icon4c.png")
      }],
      cList1: [{
        t: "装备出库",
        t1: "装备出库/入库自动登记",
        c: [{
          t: "器材领用",
          c: ["1.领用员进入到器材库，领用所需装备器材。", "2.通过门口，RFID感应门自动识读当前装备器材信息，并显示在屏幕上。", "3.领用员在屏幕上确认并录入个人信息，完成装备器材领用。"]
        }, {
          t: "器材归还",
          c: ["1.领用员将所领用的装备器材归还。", "2.通过门口，RFID感应门自动识读当前装备器材信息，并显示在屏幕上。", "3.领用员在屏幕上确认并录入归还人员信息，完成装备器材归还。"]
        }],
        img: require("@/assets/fireproof/swiper/1.jpg")
      }, {
        t: "装备清点",
        t1: "装备器材仓库清点、查找",
        c: [{
          t: "库存清点",
          c: ["1.清点人员使用RFID手持机/设备下载盘点表。", "2.手持机RFID感应功能开启后，自动采集仓库内装备器材。", "3.盘点结果上传后，生成盘点结果报表。"]
        }, {
          t: "器材查找",
          c: [" 1.输入需要查找的装备器材编号等信息。", "2.手持机RFID感应功能开启后，自动感应信号覆盖范围内的装备器材。", "3.当查找到装备器材后，界面会显示器材信息，声音提醒。"]
        }],
        img: require("@/assets/fireproof/swiper/2.jpg")
      }, {
        t: "装备保养",
        t1: "随车装备保养及清点",
        c: [{
          t: "车辆维护",
          c: "1.进入车辆维护界面，手持机车辆管理功能开启后，扫描车辆二维码，维护车辆信息及随车装备器材清单。"
        }, {
          t: "器材清点",
          c: "1.进入车辆保养清点界面，RFID自动感应功能开启后，对随车装备器材进行自动清点。"
        }],
        img: require("@/assets/fireproof/swiper/3.jpg")
      }, {
        t: " 装备退役",
        t1: "装备退役、处置登记",
        c: [{
          t: "装备退役",
          c: ["1.进入手持机装备退役管理界面，RFID自动感应功能开启后，对退役器材进行快速采集。", "2.将采集到的确认无误的数据，上传到器材管理系统，并打印及审核单据。", "3.审核完成，单据内所有器材完成退役。"]
        }, {
          t: "装备处置",
          c: ["1.进入手持机装备处置管理界面，RFID自动感应功能开启后，对报废装备进行快速采集。", "2.已退役装备的数据，采集确认无误后，上传到器材管理系统，打印及审核单据。", "3.审核完成，单据内所有装备完成处置。"]
        }],
        img: require("@/assets/fireproof/swiper/4.jpg")
      }],
      recommend: {
        title: "消防器材产品推荐",
        list: [{
          image: require("@/assets/fireproof/recommend/1.png"),
          to: "/product?name=RFID柔性/抗金属标签&current=3"
        }, {
          image: require("@/assets/fireproof/recommend/2.png"),
          to: "/product?name=RFID柔性/抗金属标签"
        }, {
          image: require("@/assets/fireproof/recommend/3.png"),
          to: "/product?name=手持机"
        }, {
          image: require("@/assets/fireproof/recommend/4.png"),
          to: "/product?name=RFID柔性/抗金属标签&current=1"
        }, {
          image: require("@/assets/fireproof/recommend/5.png"),
          to: "/product?name=RFID柔性/抗金属标签&current=2"
        }, {
          image: require("@/assets/fireproof/recommend/6.png"),
          to: "/product?name=工具柜"
        }, {
          image: require("@/assets/fireproof/recommend/7.png"),
          to: "/product?name=桌面式读写器&current=1"
        }, {
          image: require("@/assets/fireproof/recommend/8.png"),
          to: "/product?name=RFID打印机&current=2"
        }]
      }
    };
  },
  mounted: function mounted() {
    this.setHeaderColorWhite();
    new Wow.WOW({
      animateClass: "animate__animated",
      offset: 30
    }).init();
  },
  unmounted: function unmounted() {
    this.setHeaderColorBlack();
  },
  methods: _objectSpread({}, mapMutations(["setHeaderColorBlack", "setHeaderColorWhite"]))
};